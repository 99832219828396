@import url('https://fonts.googleapis.com/css2?family=Inspiration&display=swap');

.App {
  text-align: center;
}

.Title {
  left: 7%;
  font-family: 'Inspiration', cursive;
  margin: 10px;
  width: 80%;
  text-align: center;
  position: fixed;
  color: #FF8868;
  z-index: 100;
  font-size: 1.4em;
  font-weight: bold;
  background-color: #EAEAEA;
  border-radius: 5px;
  border-style: outset;
}

.Title h1 {
  margin: 0;
  padding: 10px;
}

.Teacher {
  bottom: 0;
  font-family: 'Inspiration', cursive;
  width: 100%;
  text-align: center;
  position: fixed;
  color: #5B7484;
  background-color: #C6D1D7;
  z-index: 100;
  font-size: 0.9em;
}

.Teacher h2 {
  margin: 0;
  padding: 10px;
}