.Gallery {
    width: 100%;
    height: 100%;
}

canvas {
    height: 100vh;
}

hr {
    height: 10px;
    width: 10px;
    border-radius: 25px;
}